<template>
  <div>
    <headbar></headbar>

    <div class="container">
      <hr />

      <div>*</div>

      <stripe-checkout
        ref="checkoutRef"
        mode="subscription"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (loading = v)"
      />
      <b-button @click="submit">Pay</b-button>
    </div>
  </div>
</template>

<script>
import Headbar from "../../components/headbar.vue";

// https://vuestripe.com/stripe-checkout/one-time-payment/

// https://github.com/jofftiquez/vue-stripe-checkout
// https://stripe.com/docs/payments/checkout/subscriptions/starting

// https://dashboard.stripe.com/settings/checkout enable Checkout client-only integration

import Firebase from "firebase";

import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  components: {
    StripeCheckout,
    Headbar,
  },
  data: () => ({
    loading: false,
    lineItems: [
      {
        price: "plan_HCN3zwtOgT1Juh", // The id of the one-time price you created in your Stripe dashboard
        quantity: 1,
      },
    ],
    successURL: "https://abstraktion.io/fabric/checkout/success/", //'https://127.0.0.1:8000/checkout/success',
    cancelURL: "https://abstraktion.io/fabric/checkout/cancel/", //'https://127.0.0.1:8000/checkout/cancel',

    publishableKey: "pk_live_7GbH8kz2AArLNNFUXpOFzR8H", //process.env.PUBLISHABLE_KEY,
    items: [
      {
        plan: "plan_HCN3zwtOgT1Juh",
        quantity: 1,
      },
      /*{
                    plan: 'plan_H1fAZOjdDQcfhs',
                    quantity: 1,
                }*/
    ],

    name: "name",

    token: null,
    charge: null,
    amount: 1,
  }),
  created: function () {
    Firebase.auth().onAuthStateChanged((user) => {
      //console.log(user,'*')
      /*if (user) {

                    window.gtag('event', 'app_' + user.uid, {
                        'event_category': 'stripe',
                        'event_label': 'stripe',
                        'value': this.$i18n.locale
                    })
                }*/
    });
  },
  mounted: function () {
    this.message();
    //this.error()

    // change domain

    /*if (app_parameters.app.production) {

                this.successUrl = 'http://' + app_parameters.app.domain + '/razor/checkout/success'
                this.cancelUrl = 'http://' + app_parameters.app.domain + '/razor/checkout/cancel'

            }*/
  },
  methods: {
    checkout() {
      this.$refs.checkoutRef.redirectToCheckout();
    },

    error() {
      this.$buefy.toast.open({
        duration: 5000,
        message: "error",
        position: "is-bottom",
        type: "is-danger",
      });
    },

    message() {
      this.$buefy.toast.open({
        duration: 3000,
        message: "checkout",
        position: "is-bottom",
        type: "is-success",
      });
    },

    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
      //this.$refs.elementsRef.submit();
    },
    tokenCreated(token) {
      this.token = token;
      // for additional charge objects go to https://stripe.com/docs/api/charges/object
      this.charge = {
        source: token.card,
        amount: this.amount,
        description: this.description,
      };
      this.sendTokenToServer(this.charge);
    },
    sendTokenToServer(charge) {
      // Send to server
    },
  },
};
</script>

<style lang="scss">
.ak-card__text {
  color: #fff;
  margin-bottom: 8px;
}

.ak-checkout__container {
  .ak-card {
    height: 600px;
  }
}

.notices a {
  color: #fff;
  transition: all 1s;
}
</style>